import AxiosConfig from '../../utils/axios-config.ts';
import OrderB2B from './order-b2b.ts';
import Profile from './profile.ts';
import AddressBook from './address-book.ts';
import Auth from './auth.ts';
import OrderB2C from './order-b2c.ts';

export default class CustomerService extends AxiosConfig {
  profile: Profile;
  orderB2B: OrderB2B;
  orderB2C: OrderB2C;
  addressBook: AddressBook;
  auth: Auth;

  constructor(token?: string) {
    const baseURL = '/api/customers';

    super(baseURL, token);

    this.profile = new Profile(baseURL, token);
    this.orderB2B = new OrderB2B(baseURL, token);
    this.orderB2C = new OrderB2C(baseURL, token);
    this.addressBook = new AddressBook(baseURL, token);
    this.auth = new Auth(baseURL);
  }
}
