import AxiosConfig from '../../utils/axios-config.ts';
import { ReverseOrderDetail, ReverseOrderRequest } from './responses/reverse.ts';

export default class ReverseOrder extends AxiosConfig {
  private readonly reverseOrdersEndpoint = '/orders/reversals';

  async getReverseOrder(orderId: string): Promise<ReverseOrderDetail> {
    const response = await this.useAxios.get<ReverseOrderDetail>(`${this.reverseOrdersEndpoint}/${orderId}`);

    return response.data;
  }

  async postReverseOrder(request: ReverseOrderRequest) {
    await this.useAxios.post(this.reverseOrdersEndpoint, request);
  }
}
