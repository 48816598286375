import AxiosConfig from '../../utils/axios-config.ts';
import { qs } from '../../utils/helper.ts';
import { B2COrderRequest, ServiceLevelMappingRequest } from './requests/order/b2c.ts';
import { GetServiceLevelMappingResponse } from './responses/order/b2c.ts';

export default class OrderB2C extends AxiosConfig {
  private readonly ordersEndpoint = '/orders';
  private readonly serviceLevelMappingEndpoint = '/service-level-mappings';

  async getServiceLevelMappingAPI(request: ServiceLevelMappingRequest): Promise<GetServiceLevelMappingResponse> {
    const response = await this.useAxios.get(this.serviceLevelMappingEndpoint + qs<ServiceLevelMappingRequest>(request));

    return response.data as GetServiceLevelMappingResponse;
  }

  async postB2COrdersAPI(type: 'domesticSingleForm', payload: B2COrderRequest[]) {
    return await this.useAxios.post(`${this.ordersEndpoint}/${type}`, payload);
  }
}
