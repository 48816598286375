import AxiosConfig from '../../utils/axios-config.ts';
import { qs, setDefaultIfRequired } from '../../utils/helper.ts';

// import types
import type {
  GetCredentialsRequest,
  GetEventSubscriptionsRequest,
  PatchRevokeKeyRequest,
  PostCredentialRequest,
  PostEventSubscriptionRequest,
} from './requests/profile.ts';
import type { GetCredentialsResponse, GetEventSubscriptionsResponse, GetProfileResponse } from './responses/profile.ts';

export default class Profile extends AxiosConfig {
  private readonly profileEndpoint = '/profile';
  private readonly credentialsEndpoint = '/credentials';
  private readonly subscriptionEndpoint = '/event-subscriptions';

  async getProfile(): Promise<GetProfileResponse> {
    const response = await this.useAxios.get(this.profileEndpoint);
    return response.data as GetProfileResponse;
  }

  async getCredentialsAPI(request: GetCredentialsRequest): Promise<GetCredentialsResponse> {
    const response = await this.useAxios.get(this.credentialsEndpoint + qs<GetCredentialsRequest>(setDefaultIfRequired(request)));

    return response.data as GetCredentialsResponse;
  }

  async patchRevokeKeyAPI({ id }: PatchRevokeKeyRequest) {
    return await this.useAxios.patch(`${this.credentialsEndpoint}/${id}`);
  }

  async postCredentialAPI(payload: PostCredentialRequest) {
    return await this.useAxios.post(this.credentialsEndpoint, payload);
  }

  async getEventSubscriptionsAPI(request: GetEventSubscriptionsRequest): Promise<GetEventSubscriptionsResponse> {
    const response = await this.useAxios.get(
      `${this.subscriptionEndpoint}/list` + qs<GetEventSubscriptionsRequest>(setDefaultIfRequired(request)),
    );

    return response.data as GetEventSubscriptionsResponse;
  }

  async postSubscriptionAPI(payload: PostEventSubscriptionRequest) {
    return this.useAxios.post(this.subscriptionEndpoint, payload);
  }

  async deleteSubscriptionAPI(id: string) {
    await this.useAxios.delete(this.subscriptionEndpoint + '/' + id);
  }
}
