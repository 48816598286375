import AxiosConfig from '../../utils/axios-config.ts';
import { LoginRequest } from './requests/auth.ts';
import { LoginResponse } from './responses/auth.ts';

export default class Login extends AxiosConfig {
  private readonly loginEndpoint = '/login';

  async postLogin(request: LoginRequest): Promise<LoginResponse> {
    const response = await this.useAxios.post(this.loginEndpoint, request);
    return response.data;
  }
}
