import AxiosConfig from '../../utils/axios-config.ts';
import { qs, setDefaultIfRequired } from '../../utils/helper.ts';

// * import types * //
import type { B2COrderV1, GetB2COrderDetailsResponse, GetB2COrdersResponse, GetSetAWBOrdersResponse } from './responses/orders/b2c.ts';
import type {
  GetB2COrdersRequest,
  PostB2COrderRelabelRequest,
  PatchB2COrderRequest,
  PostB2COrderReverseRequest,
  PostB2COrdersBulkUploadRequest,
  PostB2COrdersCancellationRequest,
  PostB2COrdersExportRequest,
  PostB2COrdersLabelRequest,
  PostB2COrdersSetAWBRequest,
  PostOrdersExportWithFiltersRequest,
  PostOrdersExportAsEmailRequest,
  PostB2COrdersSetUpdateAWBRequest,
  PrintLabelRequest,
  OftUpdateStatusRequest,
} from './requests/orders/b2c.ts';

export default class OrderB2C extends AxiosConfig {
  private readonly ordersEndpoint = '/orders';
  private readonly newOrdersEndpoint = '/b2c-orders';
  private readonly ordersExportEndpoint = '/orders-export';
  private readonly ordersExportAsEmailEndpoint = '/b2c-orders/export/email';

  async getB2COrdersV1API(params: GetB2COrdersRequest): Promise<GetB2COrdersResponse> {
    const queryParams = qs<GetB2COrdersRequest>(setDefaultIfRequired({ ...params }));
    const response = await this.useAxios.get<GetB2COrdersResponse>(this.ordersEndpoint + queryParams);

    return response.data;
  }

  async getB2COrdersDetailV1API(id: string): Promise<B2COrderV1> {
    const response = await this.useAxios.get<B2COrderV1>(`${this.ordersEndpoint}/${id}`);

    return response.data;
  }

  async getB2COrdersAPI(params: GetB2COrdersRequest): Promise<GetB2COrdersResponse> {
    const queryParams = qs<GetB2COrdersRequest>(setDefaultIfRequired({ ...params }));
    const response = await this.useAxios.get<GetB2COrdersResponse>(this.newOrdersEndpoint + queryParams);

    return response.data;
  }

  async getB2COrdersDetailAPI(id: string): Promise<GetB2COrderDetailsResponse> {
    const response = await this.useAxios.get<GetB2COrderDetailsResponse>(`${this.newOrdersEndpoint}/${id}`);

    return response.data;
  }

  async postOrdersExportAPI(args: PostB2COrdersExportRequest) {
    const queryParams = qs<PostB2COrdersExportRequest['params']>({ ...args.params });

    return await this.useAxios.post<ArrayBuffer>(this.ordersExportEndpoint + queryParams, args.payload, {
      responseType: 'arraybuffer',
    });
  }

  async postOrdersExportWithFiltersAPI(args: PostOrdersExportWithFiltersRequest) {
    const queryParams = qs<PostOrdersExportWithFiltersRequest['params']>({ ...args.params });

    return await this.useAxios.post<ArrayBuffer>(this.ordersExportEndpoint + queryParams, args.payload, {
      responseType: 'arraybuffer',
    });
  }

  async postOrdersExportAsEmailAPI(args: PostOrdersExportAsEmailRequest) {
    const queryParams = qs<PostOrdersExportWithFiltersRequest['params']>({ ...args.params });

    return await this.useAxios.post<ArrayBuffer>(this.ordersExportAsEmailEndpoint + queryParams, args.payload);
  }

  async postOrdersLabelAPI(payload: PostB2COrdersLabelRequest) {
    return await this.useAxios.post<ArrayBuffer>(this.ordersEndpoint + '/labels', payload, { responseType: 'arraybuffer' });
  }

  async postSetAWBAPI(payload: PostB2COrdersSetAWBRequest) {
    return await this.useAxios.post<GetSetAWBOrdersResponse>(this.ordersEndpoint + '/set-awb', payload);
  }

  async postSetAWBUpdateAPI(payload: PostB2COrdersSetUpdateAWBRequest) {
    return await this.useAxios.post(this.ordersEndpoint + '/details', payload);
  }

  async postCancellationOrdersAPI(payload: PostB2COrdersCancellationRequest) {
    return await this.useAxios.post(this.ordersEndpoint + '/cancellations', payload);
  }

  async postBulkOrdersAPI(args: PostB2COrdersBulkUploadRequest) {
    return await this.useAxios.post(this.ordersEndpoint + '/batch' + `/${args.type}`, args.payload);
  }

  async postInitiateReverseOrderAPI(args: PostB2COrderReverseRequest) {
    return await this.useAxios.post(`${this.ordersEndpoint}/${args.orderId}/reversals`, args.payload);
  }

  async postRelabelOrderAPI(payload: PostB2COrderRelabelRequest) {
    return await this.useAxios.post(`${this.ordersEndpoint}/relabel`, payload);
  }

  async patchB2COrderAPI(orderId: string, payload: PatchB2COrderRequest) {
    return await this.useAxios.patch(`${this.ordersEndpoint}/${orderId}`, payload);
  }

  async getB2COrdersLabelAPI(params: PrintLabelRequest) {
    const queryParams = qs<PrintLabelRequest>({ ...params });
    return await this.useAxios.get<ArrayBuffer>(`${this.newOrdersEndpoint}/labels${queryParams}`, {
      responseType: 'arraybuffer',
    });
  }

  async deleteOrderDetailsAPI(id: string) {
    return await this.useAxios.delete(`${this.ordersEndpoint}/details/${id}`);
  }

  async postOftUpdateStatusAPI(payload: OftUpdateStatusRequest) {
    return await this.useAxios.post(this.ordersEndpoint + '/statuses', payload);
  }
}
