import AxiosConfig from '../../utils/axios-config.ts';
import { qs, setDefaultIfRequired } from '../../utils/helper.ts';

// import types
import type { GetPartnerRequest, GetPartnerRatesRequest } from './requests/partner.ts';
import type {
  GetPartnerResponse,
  GetPartnerRatesCustomsResponse,
  GetPartnerRatesDomesticResponse,
  GetPartnerRatesGenericResponse,
  PartnerCoverage,
  PartnerDetail,
  PartnerProfile,
  PartnerRating,
} from './responses/partner.ts';

export default class Partner extends AxiosConfig {
  private readonly partnerDetailsEndpoint = '/partner-details';
  private readonly partnersEndpoint = '/partners';
  private readonly ratesEndpoint = '/rates';
  private readonly connectionsEndpoint = '/connections';

  async getPartnerDetail(partnerId: string): Promise<PartnerDetail> {
    const response = await this.useAxios.get<PartnerDetail>(`${this.partnerDetailsEndpoint}/${partnerId}`);

    return response.data;
  }

  async getPartnerRating(partnerId: string) {
    const response = await this.useAxios.get<PartnerRating>(`${this.partnersEndpoint}/${partnerId}/ratings/v2`);

    return response.data;
  }

  async getPartnerServiceType(partnerId: string): Promise<PartnerProfile> {
    const response = await this.useAxios.get<PartnerProfile>(`${this.partnersEndpoint}/${partnerId}/attributes`);

    return response.data;
  }

  async postPartnerConnection(partnerId: string) {
    await this.useAxios.post(`${this.connectionsEndpoint}/${partnerId}`);
  }

  async getPartnerCoverage(partnerId: string, isSRI = false): Promise<PartnerCoverage[]> {
    const link = isSRI ? 'sri-sla' : 'zones';
    const response = await this.useAxios.get<PartnerCoverage[]>(`${this.partnersEndpoint}/${partnerId}/${link}`);

    return response.data;
  }

  /* === START === */
  /* for partner rates */
  async getPartnerRatesFirstMile(payload: GetPartnerRatesRequest): Promise<GetPartnerRatesGenericResponse> {
    const response = await this.useAxios.get<GetPartnerRatesGenericResponse>(
      `${this.ratesEndpoint}/first-mile` + qs<GetPartnerRatesRequest>(setDefaultIfRequired(payload)),
    );

    return response.data;
  }

  async getPartnerRatesLastMile(payload: GetPartnerRatesRequest): Promise<GetPartnerRatesGenericResponse> {
    const response = await this.useAxios.get<GetPartnerRatesGenericResponse>(
      `${this.ratesEndpoint}/last-mile` + qs<GetPartnerRatesRequest>(setDefaultIfRequired(payload)),
    );

    return response.data;
  }

  async getPartnerRatesDomestic(payload: GetPartnerRatesRequest): Promise<GetPartnerRatesDomesticResponse> {
    const response = await this.useAxios.get<GetPartnerRatesDomesticResponse>(
      `${this.ratesEndpoint}/domestic` + qs<GetPartnerRatesRequest>(setDefaultIfRequired(payload)),
    );

    return response.data;
  }

  async getPartnerRatesCustoms(payload: GetPartnerRatesRequest): Promise<GetPartnerRatesCustomsResponse> {
    const response = await this.useAxios.get<GetPartnerRatesCustomsResponse>(
      `${this.ratesEndpoint}/customs` + qs<GetPartnerRatesRequest>(setDefaultIfRequired(payload)),
    );

    return response.data;
  }
  /* === END === */

  // for getting the partners
  async getPartnersAPI(params: GetPartnerRequest) {
    const queryParams = qs<GetPartnerRequest>(setDefaultIfRequired({ ...params }));
    const response = await this.useAxios.get<GetPartnerResponse>(this.partnersEndpoint + queryParams);

    return response.data;
  }
}
