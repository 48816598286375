//  meta-data pagination from the API
export interface Meta {
  page: number;
  totalPage: number;
  totalCount: number;
}

export interface PaginatedResponse<T> extends Meta {
  data: T[];
}

export interface Pagination {
  page?: number;
  perPage?: number;
}

export function qs<T>(o: T): string {
  const filteredParams = Object.fromEntries(
    Object.entries(o as Record<string, string | string[]>).filter(([_, value]) =>
      Array.isArray(value)
        ? value.length > 0 // Only include arrays with at least one item
        : value !== null && value !== undefined && value !== '',
    ),
  );

  const queryString = Object.entries(filteredParams)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((item) => `${key}=${item}`).join('&');
      } else {
        return `${key}=${value}`;
      }
    })
    .join('&');

  return queryString ? `?${queryString}` : '';
}

export function setDefaultIfRequired<T extends Pagination>(p: T) {
  if (typeof p.page === 'undefined') {
    p.page = 1;
  }
  if (typeof p.perPage === 'undefined') {
    p.perPage = 10;
  }
  return p;
}
