import axios from 'axios';
import type { AxiosInstance } from 'axios';

export default class AxiosConfig {
  readonly useAxios: AxiosInstance;

  constructor(baseURL: string, token?: string) {
    this.useAxios = axios.create({ baseURL });

    if (token) {
      this.useAxios.interceptors.request.use((config) => {
        config.headers.Authorization = token;
        return config;
      });
    }

    // Add interceptors for development environment
    // eslint-disable-next-line turbo/no-undeclared-env-vars
    if (process.env.NODE_ENV === 'development') {
      this.useAxios.interceptors.request.use((request) => {
        // console.log('Starting Request', JSON.stringify(request, null, 2));
        return request;
      });

      this.useAxios.interceptors.response.use((response) => {
        // console.log('Starting Response', JSON.stringify(response.data, null));
        return response;
      });
    }
  }
}
