/* eslint-disable @typescript-eslint/no-unsafe-return */
import AxiosConfig from '../../utils/axios-config.ts';
import { qs, setDefaultIfRequired } from '../../utils/helper.ts';

// import types
import type {
  B2BOrderDraft,
  BulkB2BOrdersPayload,
  DeleteB2BDocumentRequest,
  GetAllB2BOrdersDraftRequest,
  GetB2BLanesRequest,
  GetB2BMasterFLightScheduleRequest,
  GetB2BMasterTruckScheduleRequest,
  GetB2BOrderGeneralRequest,
  GetB2BOrderQuotation,
  GetB2BOrdersDraftResponse,
  GetB2BOrdersRequest,
  GetSpecificB2BOrderDocumentRequest,
  PostAttachB2BDocsRequest,
  PostB2BOrder,
  PostB2BOrderQuotation,
  PostB2BOrdersDraft,
  PostB2BPackingRequest,
  PostPresignedUrlsB2BDocsRequest,
  PresignedURLs,
  UpdateB2BOrderParams,
} from './requests/orders/b2b.ts';
import type {
  B2BMasterFlightSchedules,
  B2BOrderDetails,
  GetB2BMasterFlightScheduleResponse,
  GetB2BMasterTruckScheduleResponse,
  GetB2BOrderDetailsResponse,
  GetB2BOrderDocumentsResponse,
  GetB2BOrdersResponse,
  GetB2BResponse,
  GetAllB2BOrdersDraftResponse,
} from './responses/orders/b2b.ts';

export default class OrderB2B extends AxiosConfig {
  private readonly b2bOrdersEndpoint = '/b2b-orders';
  private readonly b2bGeneralEndpoint = '/b2b';
  private readonly b2bDocumentEndpoint = '/b2bs/documents';
  private readonly b2bLanesEndpoint = '/b2b/lanes';
  private readonly b2bMasterFlightScheduleEndpoint = '/master-flight-schedules';
  private readonly b2bMasterTruckScheduleEndpoint = '/master-truck-schedules';

  async getB2BOrders(request: GetB2BOrdersRequest): Promise<GetB2BOrdersResponse> {
    const response = await this.useAxios.get(this.b2bOrdersEndpoint + qs<GetB2BOrdersRequest>(setDefaultIfRequired(request)));

    return response.data as GetB2BOrdersResponse;
  }

  async postB2BOrder(body: PostB2BOrder) {
    const response = await this.useAxios.post(this.b2bOrdersEndpoint + '/?shipmentMode=' + body.shipmentMode, body);

    return response.data;
  }

  async getB2BOrderDetails(request: GetB2BOrderGeneralRequest) {
    const response = await this.useAxios.get(this.b2bOrdersEndpoint + '/' + request.orderId);

    return response.data as GetB2BOrderDetailsResponse;
  }

  async getAllB2BOrdersDraft(request: GetAllB2BOrdersDraftRequest): Promise<GetAllB2BOrdersDraftResponse> {
    const response = await this.useAxios.get(
      `${this.b2bOrdersEndpoint}/draft${qs<GetAllB2BOrdersDraftRequest>(setDefaultIfRequired(request))}`,
    );

    return response.data as GetAllB2BOrdersDraftResponse;
  }

  async getB2BOrderDocuments(request: GetB2BOrderGeneralRequest): Promise<GetB2BOrderDocumentsResponse> {
    const response = await this.useAxios.get(this.b2bGeneralEndpoint + '/' + request.orderId + '/documents');

    return response.data as GetB2BOrderDocumentsResponse;
  }

  async getSpecificB2BOrderDocument(request: GetSpecificB2BOrderDocumentRequest): Promise<string> {
    const response = await this.useAxios.get(
      this.b2bGeneralEndpoint + 's/' + request.orderId + '/documents/' + request.documentId + '/download',
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { url } = response.data;

    if (!url) throw response;

    return url;
  }

  async postPresignedUrlsB2BDocs(request: PostPresignedUrlsB2BDocsRequest) {
    const { id, data } = request;
    const response = await this.useAxios.post(this.b2bGeneralEndpoint + 's/' + id + '/presigned-urls', data);

    return response.data as PresignedURLs[];
  }

  async postAttachB2BDocs(request: PostAttachB2BDocsRequest) {
    const response = await this.useAxios.post(this.b2bGeneralEndpoint + '/' + request.id + '/attach-documents', request.data);

    return response.data;
  }

  async postB2BPackingList(request: PostB2BPackingRequest) {
    const response = await this.useAxios.post(this.b2bOrdersEndpoint + '/' + request.id + '/packing-lists');

    return response.data;
  }

  async deleteB2BDocument(request: DeleteB2BDocumentRequest) {
    const response = await this.useAxios.delete(this.b2bDocumentEndpoint + '/' + request.id);

    return response.data;
  }

  async getB2BLanes(request: GetB2BLanesRequest): Promise<GetB2BResponse> {
    const response = await this.useAxios.get(this.b2bLanesEndpoint + qs<GetB2BLanesRequest>(request));

    return response.data as GetB2BResponse;
  }

  async postB2BOrdersDraft(body: PostB2BOrdersDraft): Promise<GetB2BOrdersDraftResponse> {
    const response = await this.useAxios.post(this.b2bOrdersEndpoint + '/draft', body);

    return response.data as GetB2BOrdersDraftResponse;
  }

  async getB2BOrdersDraft(b2bOrderID: string): Promise<Partial<B2BOrderDraft>> {
    const response = await this.useAxios.get<Partial<B2BOrderDraft>>(this.b2bOrdersEndpoint + '/' + b2bOrderID + '/draft');

    return response.data;
  }

  async putB2BOrdersDraft(b2bOrderID: string, draft: B2BOrderDraft): Promise<Partial<B2BOrderDraft>> {
    const response = await this.useAxios.put<Partial<B2BOrderDraft>>(this.b2bOrdersEndpoint + '/' + b2bOrderID + '/draft', draft);

    return response.data;
  }

  async getB2BMasterFlightSchedules(request: GetB2BMasterFLightScheduleRequest): Promise<GetB2BMasterFlightScheduleResponse> {
    const response = await this.useAxios.get<GetB2BMasterFlightScheduleResponse>(
      this.b2bMasterFlightScheduleEndpoint + qs<GetB2BMasterFLightScheduleRequest>(setDefaultIfRequired(request)),
    );

    return response.data;
  }

  async getB2BMasterFlightSchedulesById(id: string): Promise<Partial<B2BMasterFlightSchedules>> {
    const response = await this.useAxios.get<Partial<B2BMasterFlightSchedules>>(this.b2bMasterFlightScheduleEndpoint + '/' + id);

    return response.data;
  }

  async getB2BMasterTruckSchedules(request: GetB2BMasterTruckScheduleRequest): Promise<GetB2BMasterTruckScheduleResponse> {
    const response = await this.useAxios.get<GetB2BMasterTruckScheduleResponse>(
      this.b2bMasterTruckScheduleEndpoint + qs<GetB2BMasterTruckScheduleRequest>(setDefaultIfRequired(request)),
    );

    return response.data;
  }

  async postB2BOrderQuotation(body: PostB2BOrderQuotation) {
    const { shipmentMode } = body;
    const queryParams = { shipmentMode };
    const response = await this.useAxios.post(this.b2bGeneralEndpoint + '/quotations' + qs(queryParams), body);

    // return response.data as GetB2BOrderQuotation;

    return response.data as GetB2BOrderQuotation;
  }

  async getB2BOrderQuotation(request: string): Promise<GetB2BOrderQuotation> {
    const response = await this.useAxios.get(this.b2bGeneralEndpoint + '/quotations/' + request);

    return response.data as GetB2BOrderQuotation;
  }

  async postBulkB2BOrders(request: BulkB2BOrdersPayload) {
    return await this.useAxios.post(this.b2bOrdersEndpoint + '/bulk-uploads', request);
  }

  async putB2BOrder(b2bOrderID: string, b2bOrder: UpdateB2BOrderParams): Promise<Partial<B2BOrderDetails>> {
    const response = await this.useAxios.put<Partial<B2BOrderDetails>>(this.b2bOrdersEndpoint + '/' + b2bOrderID, b2bOrder);

    return response.data;
  }

  async postB2BUpdatePackingList(b2bOrderID: string, body: UpdateB2BOrderParams) {
    const response = await this.useAxios.post(`${this.b2bOrdersEndpoint}/${b2bOrderID}/packing-lists/update-b2b-order`, body);
    return response.data;
  }
}
