import AxiosConfig from '../../utils/axios-config.ts';
import Password from './password.ts';
import MFA from './mfa.ts';

export type DomainTypes = 'clients' | 'customers';

export default class AuthService extends AxiosConfig {
  password: Password;
  mfa: MFA;

  constructor(token?: string, type: DomainTypes = 'clients') {
    const baseURL = '/api';

    super(baseURL, token);

    this.password = new Password(baseURL, token);
    this.mfa = new MFA(baseURL, token, type);
  }
}
