import AxiosConfig from '../../utils/axios-config.ts';

// * import types * //
import type { GetStatusesResponse } from './responses/statuses.ts';

export default class Statuses extends AxiosConfig {
  private readonly statusesEndpoint = '/statuses';

  async getStatuses() {
    const response = await this.useAxios.get<GetStatusesResponse>(this.statusesEndpoint);

    return response.data;
  }
}
