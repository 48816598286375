import AxiosConfig from '../../utils/axios-config.ts';

// import types
import type { DomainTypes } from './index.ts';
import type { PostVerifyMFARequest } from './requests/mfa.js';
import type { GetStatusMFAResponse, PostGenerateMFAResponse } from './responses/mfa.js';

export default class MFA extends AxiosConfig {
  private baseMFAEndpoint;
  private generateMFAEndpoint;
  private disableMFAEndpoint;
  private statusMFAEndpoint;
  private verifyMFAEndpoint;

  constructor(baseURL: string, token?: string, type: DomainTypes = 'clients') {
    super(baseURL, token);

    this.baseMFAEndpoint = `/${type}/otp`;
    this.generateMFAEndpoint = this.baseMFAEndpoint + '/generate';
    this.disableMFAEndpoint = this.baseMFAEndpoint + '/disable';
    this.statusMFAEndpoint = this.baseMFAEndpoint + '/status';
    this.verifyMFAEndpoint = this.baseMFAEndpoint + '/verify';
  }

  async postGenerateMFAAPI() {
    return await this.useAxios.post<PostGenerateMFAResponse>(this.generateMFAEndpoint);
  }

  async postDisableMFAAPI() {
    return await this.useAxios.post(this.disableMFAEndpoint);
  }

  async getStatusMFAAPI() {
    const response = await this.useAxios.get<GetStatusMFAResponse>(this.statusMFAEndpoint);

    return response?.data;
  }

  async postVerifyMFAAPI(payload: PostVerifyMFARequest) {
    return await this.useAxios.post(this.verifyMFAEndpoint, payload);
  }
}
