import AxiosConfig from '../../utils/axios-config.ts';

// import types
import type { PatchPasswordRequest, ResetPasswordRequest } from './requests/password.js';

export default class Password extends AxiosConfig {
  private readonly resetPasswordEndpoint = '/password-reset';
  private readonly updatePasswordEndpoint = '/user/password';

  async resetPassword(request: ResetPasswordRequest) {
    await this.useAxios.post(this.resetPasswordEndpoint, request);
  }

  async patchPassword(request: PatchPasswordRequest) {
    await this.useAxios.patch(this.updatePasswordEndpoint, request);
  }
}
