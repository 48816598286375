import AxiosConfig from '../../utils/axios-config.ts';

// import types
import type { GetCarrierTypesRequest } from './requests/carrier-types.ts';
import type { GetCarrierTypesResponses } from './responses/carrier-types.ts';
// define types
type GetCarrierTypesAPIResponse = GetCarrierTypesResponses;
export default class Country extends AxiosConfig {
  private readonly carrierTypesEndpoint = '/carrier-types';

  async getCarrierTypesAPI(params?: GetCarrierTypesRequest): Promise<GetCarrierTypesAPIResponse[]> {
    const queryParams = {
      shipmentMode: params?.shipmentMode || null,
      originSubzoneID: params?.originSubzoneID || null,
      destinationSubzoneID: params?.destinationSubzoneID || null,
    };

    const responses = await this.useAxios.get<GetCarrierTypesResponses[]>(this.carrierTypesEndpoint, {
      params: queryParams,
    });

    return responses.data;
  }
}
