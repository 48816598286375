/* this is for the OMS Customer path endpoint */

import AxiosConfig from '../../utils/axios-config.ts';
import { qs, setDefaultIfRequired } from '../../utils/helper.ts';

// import types
import type {
  DeleteCustomerB2BRatesRequest,
  DeleteCustomersRequest,
  GetCustomerB2BRateDetailsRequest,
  GetCustomerB2BRatesRequest,
  GetCustomerDetailsRequest,
  GetCustomersRequest,
  GetCustomerUserDetailsRequest,
  GetCustomerUsersRequest,
  PatchCustomerAddOnsRequest,
  PatchCustomerB2BRatesRequest,
  PatchCustomerOrderCodeConfigRequest,
  PatchCustomerUserRequest,
  PatchSetCustomerUserStatusRequest,
  PostCustomerB2BRatesRequest,
  PostCustomerLabelConfigRequest,
  PostCustomerRatesRequest,
  PostCustomerRequest,
  PostCustomerUserRequest,
  PostCustomerUserResendPasswordEmailRequest,
  PostOMSEnablerRequest,
  PostStatusMappingsRequest,
  PutLabelLogoConfigurationRequest,
} from './requests/customer.ts';
import { PostServiceLevelMappingRequest } from './requests/customer.ts';
import type {
  GetCustomerAddOnsResponse,
  GetCustomerB2BRateDetailsResponse,
  GetCustomerB2BRatesResponse,
  GetCustomerDetailsResponse,
  GetCustomerLabelConfigResponse,
  GetCustomerOrdeConfigResponse,
  GetCustomersResponse,
  GetCustomerUserDetailsResponse,
  GetCustomerUsersResponse,
  GetLabelLogoConfigurationResponse,
  GetServiceLevelMappingResponse,
  GetStatusMappingsResponse,
} from './responses/customer.ts';

export default class Customer extends AxiosConfig {
  /* customer management */
  private readonly customersEndpoint = '/customers';
  private readonly customerEmailPasswordResetEndpoint = '/password-reset/customers/send-email';
  /* status mappings */
  private readonly statusMappingsEndpoint = '/status-mappings';
  /* logo preferences */
  private readonly labelConfigurationEndpoint = '/logo-preference';
  /* order code config */
  private readonly orderCodeFormatEndpoint = '/orders/set-code-format';
  private readonly orderCodeConfigEndpoint = '/orders/order-code-config';
  /* label config */
  private readonly luwjistikLabelOnlyEndpoint = '/label/luwjistik-label-only';
  // * customer add-ons * //
  private readonly customerAddOnsEndpoint = '/oms/addons';
  // * customer b2b rates* //
  private readonly customerB2BRatesEndpoint = '/customer-rate-configs';
  /* customer management */
  private serviceLevelMappingsEndpoint: string = '/service-level-mappings';

  async getCustomers(request: GetCustomersRequest): Promise<GetCustomersResponse> {
    const response = await this.useAxios.get<GetCustomersResponse>(
      this.customersEndpoint + qs<GetCustomersRequest>(setDefaultIfRequired(request)),
    );

    return response.data;
  }

  /* === START === */

  async deleteCustomers(request: DeleteCustomersRequest) {
    await this.useAxios.delete(this.customersEndpoint + '/' + request.customerId);
  }

  async postCustomersAPI(body: PostCustomerRequest) {
    await this.useAxios.post(this.customersEndpoint, body);
  }

  async patchCustomersAPI(id: string, body: PostCustomerRequest) {
    await this.useAxios.patch(this.customersEndpoint + '/' + id, body);
  }

  async getCustomerDetailsAPI(request: GetCustomerDetailsRequest) {
    const response = await this.useAxios.get(this.customersEndpoint + '/' + request.customerId);

    return response.data as GetCustomerDetailsResponse;
  }

  async postResendUserResetPasswordMailAPI(body: PostCustomerUserResendPasswordEmailRequest) {
    await this.useAxios.post(this.customerEmailPasswordResetEndpoint, body);
  }

  /* customer users management */
  async getCustomerUsersAPI(request: GetCustomerUsersRequest): Promise<GetCustomerUsersResponse> {
    const response = await this.useAxios.get<GetCustomerUsersResponse>(
      this.customersEndpoint + `/${request.customerId}/users` + qs<GetCustomerUsersRequest>(setDefaultIfRequired(request)),
    );

    return response.data;
  }

  async getCustomerUserDetailsAPI(request: GetCustomerUserDetailsRequest): Promise<GetCustomerUserDetailsResponse> {
    const response = await this.useAxios.get<GetCustomerUserDetailsResponse>(
      this.customersEndpoint + `/${request.customerId}/users/${request.userId}`,
    );

    return response.data;
  }

  /* === END === */

  /* === START === */

  async postCustomerUsersAPI(customerId: string, body: PostCustomerUserRequest) {
    await this.useAxios.post(this.customersEndpoint + `/${customerId}/users`, body);
  }

  async patchCustomerUsersAPI(customerId: string, userId: string, body: PatchCustomerUserRequest) {
    await this.useAxios.patch(this.customersEndpoint + `/${customerId}/users/${userId}`, body);
  }

  async patchSetCustomerUserStatusAPI(customerId: string, userId: string, body: PatchSetCustomerUserStatusRequest) {
    await this.useAxios.patch(this.customersEndpoint + `/${customerId}/users/${userId}/disable`, body);
  }

  /* order code configuration */
  async getOrderCodeConfigAPI(): Promise<GetCustomerOrdeConfigResponse> {
    const response = await this.useAxios.get(this.orderCodeFormatEndpoint);

    return response.data as GetCustomerOrdeConfigResponse;
  }

  async patchOrderCodeConfigAPI(body: PatchCustomerOrderCodeConfigRequest) {
    await this.useAxios.patch(this.orderCodeConfigEndpoint, body);
  }

  /* === END === */

  /* === START === */

  /* label configuration */
  async getLabelConfigAPI(): Promise<GetCustomerLabelConfigResponse> {
    const response = await this.useAxios.get(this.luwjistikLabelOnlyEndpoint);

    return response.data as GetCustomerLabelConfigResponse;
  }

  async postLabelConfigAPI(body: PostCustomerLabelConfigRequest) {
    await this.useAxios.post(this.luwjistikLabelOnlyEndpoint, body);
  }

  /* === END === */

  /* === START === */

  /* label logo configuration */
  async getLabelLogoConfiguration(): Promise<GetLabelLogoConfigurationResponse> {
    const response = await this.useAxios.get(this.labelConfigurationEndpoint);

    return response.data as GetLabelLogoConfigurationResponse;
  }

  async putLabelLogoConfiguration(request: PutLabelLogoConfigurationRequest) {
    return await this.useAxios.put(this.labelConfigurationEndpoint, request);
  }

  /* === END === */

  /* === START === */

  /* status mappings */
  async getStatusMappingsAPI() {
    const response = await this.useAxios.get<GetStatusMappingsResponse>(this.statusMappingsEndpoint);

    return response?.data;
  }

  async postStatusMappingsAPI(body: PostStatusMappingsRequest[]) {
    return await this.useAxios.post(this.statusMappingsEndpoint, body);
  }

  /* === END === */

  /* === START === */

  async postOMSEnablerAPI(body: PostOMSEnablerRequest) {
    return await this.useAxios.post(`${this.statusMappingsEndpoint}/oms-enabler`, body);
  }

  /* customer rates */
  async customerRatesAPI(customerId: string, payload: PostCustomerRatesRequest) {
    const url = this.customerRatesEndpoint(customerId);

    return await this.useAxios.post(url, payload);
  }

  async downloadCustomerRatesAPI(customerId: string) {
    const url = this.customerRatesEndpoint(customerId);

    return await this.useAxios.get(url, { responseType: 'arraybuffer' });
  }

  async downloadCustomerRatesTemplateAPI(customerId: string) {
    const url = `${this.customerRatesEndpoint(customerId)}/template.xlsx`;

    return await this.useAxios.get(url, { responseType: 'arraybuffer' });
  }

  /* === END === */

  /* === START === */

  async deleteCustomerRatesAPI(customerId: string) {
    const url = this.customerRatesEndpoint(customerId);

    return await this.useAxios.delete(url);
  }

  // * customer add-ons * //
  async getCustomerAddOnsAPI() {
    const response = await this.useAxios.get<GetCustomerAddOnsResponse>(this.customerAddOnsEndpoint);

    return response.data;
  }

  async patchCustomerAddOnsAPI(body: PatchCustomerAddOnsRequest) {
    return await this.useAxios.patch(this.customerAddOnsEndpoint, body);
  }

  /* === END === */

  // * customer b2b-rates * //
  async getCustomerB2BRatesAPI(params: GetCustomerB2BRatesRequest) {
    const queryParams = qs<GetCustomerB2BRatesRequest>(setDefaultIfRequired({ ...params }));
    const response = await this.useAxios.get<GetCustomerB2BRatesResponse>(this.customerB2BRatesEndpoint + queryParams);

    return response.data;
  }

  async getCustomerB2BRateDetailsAPI(request: GetCustomerB2BRateDetailsRequest) {
    const response = await this.useAxios.get(this.customerB2BRatesEndpoint + '/' + request.groupID);

    return response.data as GetCustomerB2BRateDetailsResponse;
  }

  /* === START === */

  async postCustomerB2BRatesAPI(body: PostCustomerB2BRatesRequest) {
    return await this.useAxios.post(this.customerB2BRatesEndpoint, body);
  }

  async patchCustomerB2BRatesAPI(groupID: string, body: PatchCustomerB2BRatesRequest) {
    return await this.useAxios.patch(this.customerB2BRatesEndpoint + `/${groupID}`, body);
  }

  async deleteCustomerB2BRatesAPI(request: DeleteCustomerB2BRatesRequest) {
    await this.useAxios.delete(this.customerB2BRatesEndpoint + '/' + request.groupid);
  }

  async getServiceLevelMappings() {
    const response = await this.useAxios.get(this.serviceLevelMappingsEndpoint);
    return response.data as GetServiceLevelMappingResponse;
  }

  async postServiceLevelMappings(body: PostServiceLevelMappingRequest) {
    return await this.useAxios.post(this.serviceLevelMappingsEndpoint, body);
  }

  private readonly customerDetailsEndpoint = (id: string) => `${this.customersEndpoint}/${id}`;

  /* customer rates */
  private readonly customerRatesEndpoint = (id: string) => {
    const baseEndpoint = this.customerDetailsEndpoint(id);

    return `${baseEndpoint}/rates`;
  };

  /* === END === */
}
