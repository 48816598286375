import AxiosConfig from '../../utils/axios-config.ts';
import { qs, setDefaultIfRequired } from '../../utils/helper.ts';

// * import types * //
import type {
  GetModularExpressLanesRequest,
  PostModularExpressLanesRequest,
  DeleteModularExpressLanesRequest,
  PostSRILanesRequest,
  DeleteSRILanesRequest,
  GetServiceablePortsRequest,
  GetServiceableZonesRequest,
  DeleteRuleGroupLaneRequest,
  GetRuleGroupLaneRequest,
  PostRuleGroupLaneRequest,
  GetServiceablePartnersRequest,
  PutRuleGroupLaneRequest,
  GetServiceableDomesticPartnersRequest,
} from './requests/l-control.ts';
import type {
  GetModularExpressLanesResponse,
  GetRuleGroupLaneDetailResponse,
  GetRuleGroupLaneResponse,
  GetServiceablePartnersResponse,
  GetServiceablePortsResponse,
  GetServiceableZonesResponse,
  GetSRILanesResponse,
} from './responses/l-control.ts';

export default class LControl extends AxiosConfig {
  // * for modular express lanes * //
  private readonly modularExpressLanesEndpoint = '/modular-express-lanes';

  // * for sri lanes * //
  private readonly sriLanesEndpoint = '/sri/lanes';

  // * for new l-control revamp * //
  private readonly ruleGroupLanesEndpoint = '/rule-group-lanes';
  private readonly serviceablePortsEndpoint = '/serviceable-ports';
  private readonly serviceableZonesEndpoint = '/serviceable-zones';
  private readonly serviceablePartnersEndpoint = '/serviceable-partners';

  // * for modular express lanes * //
  async getModularExpressLanesAPI(params: GetModularExpressLanesRequest) {
    const queryParams = qs<GetModularExpressLanesRequest>(setDefaultIfRequired({ ...params }));
    const response = await this.useAxios.get<GetModularExpressLanesResponse>(this.modularExpressLanesEndpoint + queryParams);

    return response.data;
  }

  async postModularExpressLanesAPI(payload: PostModularExpressLanesRequest) {
    return await this.useAxios.post(this.modularExpressLanesEndpoint, payload);
  }

  async deleteModularExpressLanesAPI(payload: DeleteModularExpressLanesRequest) {
    return await this.useAxios.delete(this.modularExpressLanesEndpoint + '/' + payload.id);
  }
  // * for sri lanes * //
  async getSRILanesAPI() {
    const response = await this.useAxios.get<GetSRILanesResponse>(this.sriLanesEndpoint);

    return response.data;
  }

  async postSRILanesAPI(payload: PostSRILanesRequest) {
    return await this.useAxios.post(this.sriLanesEndpoint, payload);
  }

  async deleteSRILanesAPI(payload: DeleteSRILanesRequest) {
    return await this.useAxios.delete(this.sriLanesEndpoint, { data: payload });
  }

  // * for new l-control revamp * //
  async getServiceablePortsAPI(params: GetServiceablePortsRequest) {
    const response = await this.useAxios.get<GetServiceablePortsResponse>(this.serviceablePortsEndpoint, { params });

    return response?.data?.ports || null;
  }

  async getServiceableZonesAPI(params: GetServiceableZonesRequest) {
    const response = await this.useAxios.get<GetServiceableZonesResponse>(this.serviceableZonesEndpoint, { params });

    return response?.data?.zones || null;
  }

  async getServiceablePartnersAPI(params: GetServiceablePartnersRequest) {
    const queryParams = qs<GetServiceablePartnersRequest>(params);
    const response = await this.useAxios.get<GetServiceablePartnersResponse>(this.serviceablePartnersEndpoint + queryParams);

    return response?.data || [];
  }

  async getServiceableDomesticPartnersAPI(params: GetServiceableDomesticPartnersRequest) {
    const queryParams = qs<GetServiceableDomesticPartnersRequest>(params);
    const response = await this.useAxios.get<{ partners: GetServiceablePartnersResponse }>(
      this.serviceablePartnersEndpoint + queryParams,
    );

    return response?.data?.partners || [];
  }
  async getRuleGroupLaneAPI(params: GetRuleGroupLaneRequest) {
    const queryParams = qs<GetRuleGroupLaneRequest>({ ...params });
    const response = await this.useAxios.get<GetRuleGroupLaneResponse>(this.ruleGroupLanesEndpoint + queryParams);

    return response.data;
  }

  async getRuleGroupLaneDetailAPI(laneId: string) {
    const response = await this.useAxios.get<GetRuleGroupLaneDetailResponse>(`${this.ruleGroupLanesEndpoint}/${laneId}`);

    return response.data;
  }

  async postRuleGroupLaneAPI(payload: PostRuleGroupLaneRequest) {
    return await this.useAxios.post(this.ruleGroupLanesEndpoint, payload);
  }

  async putRuleGroupLaneAPI(payload: PutRuleGroupLaneRequest) {
    return await this.useAxios.put(this.ruleGroupLanesEndpoint + '/' + payload.id, { ruleGroup: payload.ruleGroup });
  }

  async deleteRuleGroupLaneAPI(payload: DeleteRuleGroupLaneRequest) {
    return await this.useAxios.delete(this.ruleGroupLanesEndpoint + '/' + payload.id);
  }
}
