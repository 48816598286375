import AxiosConfig from '../../utils/axios-config.ts';
import Country from './country.ts';
import CarrierTypes from './carrier-types.ts';
import ReverseOrder from './reverse-order.ts';
import Statuses from './statuses.ts';

// * import types * //
import type { GetSsoClientResponse } from './responses/sso.js';

export default class PublicService extends AxiosConfig {
  country: Country;
  carrierTypes: CarrierTypes;
  reverseOrder: ReverseOrder;
  statuses: Statuses;
  private readonly ssoClientsEndpoint = '/sso-clients';

  constructor() {
    const baseURL = '/api/data';

    super(baseURL);

    this.country = new Country(baseURL);
    this.carrierTypes = new CarrierTypes(baseURL);
    this.reverseOrder = new ReverseOrder('/api');
    this.statuses = new Statuses(baseURL);
  }

  async getSsoClientsAPI(): Promise<GetSsoClientResponse[]> {
    const response = await this.useAxios.get<GetSsoClientResponse[]>(this.ssoClientsEndpoint);
    return response.data;
  }
}
