import AxiosConfig from '../../utils/axios-config.ts';

// * import types * //
import type { GetCountriesRequest } from './requests/country.ts';
import type { GetCountriesResponses, Country as CountryType, GetRegionCountriesResponses } from './responses/country.ts';

// * define types * //
type GetCountiresAPIResponse<T extends boolean> = T extends true ? CountryType[] : GetCountriesResponses;

export default class Country extends AxiosConfig {
  private readonly countriesEndpoint = '/country-codes';
  private readonly regionCountriesEndpoint = '/country-regions';

  async getCountriesAPI(
    params?: GetCountriesRequest,
    options = { format: true },
  ): Promise<GetCountiresAPIResponse<(typeof options)['format']>> {
    const queryParams = {
      active_only: params?.activeOnly || null,
      region: params?.region || null,
      list_all: params?.listAll || null,
    };
    const responses = await this.useAxios.get<GetCountriesResponses>(this.countriesEndpoint, {
      params: queryParams,
    });

    // format the countries data
    if (options?.format) {
      const countries = [] as CountryType[];
      for (const [key, value] of Object.entries(responses.data)) {
        // check if 'key' is a direct property of countries data
        if (!Object.prototype.hasOwnProperty.call(responses.data, key)) continue;

        // check if 'key' and 'value' are truthy (not null or undefined)
        if (key && value) {
          // define the formatted data
          countries.push({
            name: key,
            code: value,
          });
        }
      }

      return countries;
    }

    return responses.data;
  }

  async getRegionCountriesAPI() {
    const responses = await this.useAxios.get<GetRegionCountriesResponses>(this.regionCountriesEndpoint);

    return responses?.data;
  }
}
