import AxiosConfig from '../../utils/axios-config.ts';
import { GetCustomerServiceLevelsResponse, GetCustomerAdvancedWorkflowsResponse } from './responses/service-level.js';
import {
  GetCustomerServiceLevelsRequest,
  GetCustomerAdvancedWorkflowsRequest,
  PostBatchCustomerServiceLevelRequest,
  PostSingleCustomerServiceLevelRequest,
  PostCustomerAdvancedWorkflowsRequest,
} from './requests/service-level.js';
import { qs } from '../../utils/helper.js';

export default class ServiceLevel extends AxiosConfig {
  private readonly defaultCustomerServiceLevelsEndpoint = '/default-customer-service-levels';
  private readonly defaultCustomerServiceLevelsPostEndpoint = '/default-customer-service-level';
  private readonly customerServiceLevelWorkflowsEndpoint = '/customer-service-level-workflows';

  async getCustomerServiceLevels(request: GetCustomerServiceLevelsRequest): Promise<GetCustomerServiceLevelsResponse> {
    const params = qs<GetCustomerServiceLevelsRequest>(request);
    const response = await this.useAxios.get(this.defaultCustomerServiceLevelsEndpoint + params);
    return response.data as GetCustomerServiceLevelsResponse;
  }

  async postSingleServiceLevel(payload: PostSingleCustomerServiceLevelRequest) {
    await this.useAxios.post(this.defaultCustomerServiceLevelsPostEndpoint, payload);
  }

  async postBatchServiceLevel(payload: PostBatchCustomerServiceLevelRequest) {
    await this.useAxios.post(this.defaultCustomerServiceLevelsPostEndpoint + '/batch', payload);
  }

  async getCustomerAdvancedWorkflows(request: GetCustomerAdvancedWorkflowsRequest): Promise<GetCustomerAdvancedWorkflowsResponse> {
    const params = qs<GetCustomerAdvancedWorkflowsRequest>(request);
    const response = await this.useAxios.get<GetCustomerAdvancedWorkflowsResponse>(
      this.customerServiceLevelWorkflowsEndpoint + params,
    );
    return response.data;
  }

  async postCustomerAdvancedWorkflows(payload: PostCustomerAdvancedWorkflowsRequest) {
    return await this.useAxios.post(this.customerServiceLevelWorkflowsEndpoint, payload);
  }

  async putCustomerAdvancedWorkflows(groupID: string, payload: PostCustomerAdvancedWorkflowsRequest) {
    return await this.useAxios.put(this.customerServiceLevelWorkflowsEndpoint + `/${groupID}`, payload);
  }

  async deleteCustomerAdvancedWorkflows(groupID: string) {
    return await this.useAxios.delete(this.customerServiceLevelWorkflowsEndpoint + `/${groupID}`);
  }
}
