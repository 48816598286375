import AxiosConfig from '../../utils/axios-config.ts';
import { GetSendBirdChatRoomRequest, PostSendBirdChatRoomRequest } from './requests/sendbird.ts';
import { qs } from '../../utils/helper.js';

// import types
import type { GetSendbirdChatRoomResponse, GetSendBirdUserResponse } from './responses/sendbird.ts';

export default class Sendbird extends AxiosConfig {
  private readonly sendBirdEndpoint = '/chat';
  private readonly threadEndpoint = '/thread';

  async getSendBirdUserAPI(): Promise<GetSendBirdUserResponse> {
    const response = await this.useAxios.get(this.sendBirdEndpoint + '/user');

    return response.data as GetSendBirdUserResponse;
  }

  async getSendbirdChatRoomAPI(request: GetSendBirdChatRoomRequest): Promise<GetSendbirdChatRoomResponse> {
    const params = qs<GetSendBirdChatRoomRequest>(request);
    const response = await this.useAxios.get<GetSendbirdChatRoomResponse>(this.threadEndpoint + params);
    return response.data as GetSendbirdChatRoomResponse;
  }

  async postSendbirdChatRoomAPI(body: PostSendBirdChatRoomRequest) {
    const response = await this.useAxios.post(this.threadEndpoint, body);

    return response.data;
  }
}
