import AxiosConfig from '../../utils/axios-config.ts';
import { qs, setDefaultIfRequired } from '../../utils/helper.ts';
import { CreateAddressBookInBulkRequest, GetAddressBookRequest, UpsertAddressBookRequest } from './requests/address-book.ts';
import { AddressBook as AddressBookModel, GetAddressBookResponse } from './responses/address-book.ts';
import { AxiosResponse } from 'axios';

export default class AddressBook extends AxiosConfig {
  private readonly endpoint = '/address-books';

  async getAddressBooks(request: GetAddressBookRequest): Promise<GetAddressBookResponse> {
    const response = await this.useAxios.get(this.endpoint + qs<GetAddressBookRequest>(setDefaultIfRequired(request)));

    return response.data as GetAddressBookResponse;
  }

  async upsertAddressBook(payload: UpsertAddressBookRequest): Promise<AddressBookModel> {
    const response = await this.useAxios.post(this.endpoint, payload);
    return response.data as AddressBookModel;
  }

  async createAddressBookInBulk(payload: CreateAddressBookInBulkRequest): Promise<void> {
    return this.useAxios.post(this.endpoint + '/bulk', payload);
  }

  async deleteAddressBook(id: string): Promise<AxiosResponse> {
    return this.useAxios.delete(this.endpoint + '/' + id);
  }
}
