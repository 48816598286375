import AxiosConfig from './utils/axios-config.ts';
import helper from './utils/axios-config.ts';

// import domains
import PublicService from './domains/public/index.ts';
import AuthService from './domains/auth/index.ts';
import CustomerService from './domains/customer/index.ts';
import ClientService from './domains/client/index.ts';

export const APIUtils = {
  axios: AxiosConfig,
  helper: helper,
};

export const APIService = {
  public: PublicService,
  auth: AuthService,
  customer: CustomerService,
  client: ClientService,
};
