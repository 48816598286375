import AxiosConfig from '../../utils/axios-config.ts';
import { qs } from '../../utils/helper.ts';
import { GetZoneRequest } from './requests/zone.ts';
import { GetZoneResponse } from './responses/zone.ts';

export default class Zone extends AxiosConfig {
  private readonly zonesEndpoint = '/zones';

  async getZonesAPI(params?: GetZoneRequest): Promise<GetZoneResponse> {
    const queryParams = qs<GetZoneRequest>({ ...params });
    const response = await this.useAxios.get<GetZoneResponse>(this.zonesEndpoint + queryParams);

    return response.data as GetZoneResponse;
  }
}
